import { HomePageFooter } from "@src/service/getHeader";

export const FOOTER_DATA: HomePageFooter = {
  certifications: [],
  courseIndex: [],
  events: [],
  resources: [],
  solutions: [],
  trainingLibrary: [],
};
