import Link from "next/link";
import React from "react";

/* Components */
import { Text } from "@components";
import { subHeaderContainer } from "./styled.css";

/* Services */
import { useGetHeader } from "@src/service/getHeader";

/* Hooks */
import { useUniqueId } from "@src/hooks";

/* Types */
import type { useDragReturn } from "@src/hooks";
import type { NavigationHeaders } from "@src/service/getHeader";

type Props = {
  children?: React.ReactNode;
  handlers: useDragReturn<HTMLDivElement>[1];
  ref?: React.RefObject<HTMLDivElement>;
};

export const SubHeaderItems = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { handlers } = props;

    /* APIs */
    const [query] = useGetHeader<NavigationHeaders[]>("subheader");

    /* Memos */
    const key = useUniqueId("subheader-items-idx-");

    return (
      <div className={subHeaderContainer} {...handlers} ref={ref}>
        {query.data.data?.map((item, i) => (
          <Link
            id="drag-item"
            onClick={(e) => e.stopPropagation()}
            className="select-none"
            key={key + i}
            href={`/course/${item.headerUrl}`}
          >
            <Text className="select-none whitespace-nowrap">
              {item.headerName}
            </Text>
          </Link>
        ))}
      </div>
    );
  },
);

SubHeaderItems.displayName = "SubHeaderItems";
