"use client";

import Link from "next/link";
import React from "react";
import Skeleton from "react-loading-skeleton";

/* Components */
import { Text } from "@components";

/* Utils */
import { withSuspense } from "@utils/withSuspense";

type Props = {
  isMobile?: boolean;
};

const NavigationItems: React.FC<Props> = (props) => {
  const { isMobile } = props;

  return (
    <div className={isMobile ? "flex flex-col gap-12" : "hidden lg:flex gap-8"}>
      <Link href="/">
        <Text as="span" size={isMobile ? "lg" : "base"} weight={500}>
          Home
        </Text>
      </Link>
      <Link href="/courses">
        <Text as="span" size={isMobile ? "lg" : "base"} weight={500}>
          Learnings
        </Text>
      </Link>
      <Link href="/projects">
        <Text as="span" size={isMobile ? "lg" : "base"} weight={500}>
          Projects
        </Text>
      </Link>
      <Link href="/services">
        <Text as="span" size={isMobile ? "lg" : "base"} weight={500}>
          Services
        </Text>
      </Link>
      <Link href="/pricing">
        <Text as="span" size={isMobile ? "lg" : "base"} weight={500}>
          Pricing
        </Text>
      </Link>
    </div>
  );
};

export const DesktopNavItems = withSuspense<Props>(
  NavigationItems,
  <Skeleton
    containerClassName="hidden lg:flex justify-evenly items-center"
    count={5}
    width={64}
    inline
  />,
);

export const MobileNavItems = withSuspense<Props>(
  NavigationItems,
  <Skeleton
    containerClassName="flex flex-col gap-12 justify-evenly items-center"
    count={5}
    width={64}
    inline
  />,
);
