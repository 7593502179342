import React from "react";
import Link from "next/link";

/* Components */
import { Heading, Text } from "@components";

/* Hooks */
import { useUniqueId } from "@src/hooks";

/* Type */
import { HomePageFooterItem } from "@src/service/getHeader";

type Props = {
  children?: React.ReactNode;
  items: HomePageFooterItem[];
  title: string;
};

export const NavigationItems: React.FC<Props> = (props) => {
  const { items, title } = props;

  /* Key */
  const key = useUniqueId("footer-navigation-item-");
  return (
    <div className="flex flex-col gap-6 mb-6">
      <Heading as="h3">{title}</Heading>
      <div className="flex flex-col gap-2">
        {items?.map((item, i) => (
          <Link key={key + i} href={item.HeaderUrl || "#"}>
            <Text>{item.headerName}</Text>
          </Link>
        ))}
      </div>
    </div>
  );
};
