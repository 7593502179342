import { create } from "zustand";

/* Utils */
import { LOCAL_STORAGE_KEYS } from "@src/consts";
import { setLocalStoragevalue } from "@utils/localStorage";

export type ThemeValues = "dark" | "light";

type ThemeState = {
  currentTheme: ThemeValues;
  isDark: boolean;
  setTheme: (theme: ThemeValues) => void;
  toggleTheme: () => void;
};

const themeStore = create<ThemeState>((set) => ({
  currentTheme: "dark",
  isDark: true,
  setTheme: (val) => {
    setLocalStoragevalue(LOCAL_STORAGE_KEYS.THEME, val);
    set({ currentTheme: val, isDark: val === "dark" });
  },
  toggleTheme: () => {
    set((state) => {
      const updatedTheme = state.currentTheme === "dark" ? "light" : "dark";
      setLocalStoragevalue(LOCAL_STORAGE_KEYS.THEME, updatedTheme);
      return {
        currentTheme: updatedTheme,
        isDark: updatedTheme === "dark",
      };
    });
  },
}));

export const useTheme = () =>
  themeStore((state) => ({
    currentTheme: state.currentTheme,
    setTheme: state.setTheme,
    toggleTheme: state.toggleTheme,
  }));

export const useDark = () => themeStore((state) => ({ isDark: state.isDark }));
