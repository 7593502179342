"use client";

import { Formik, type FormikHelpers } from "formik";
import { useSignup } from "@src/service/auth";
import React, { useCallback, useState } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

/* Components */
import { Modal } from "@components/Modal";
import { Input } from "@components/Input";
import { ThirdPartyAuth } from "../shared";
import { Button, Heading, Text, Toast } from "@components";

/* Stores */
import { useRegisterModal } from "@src/store/modalStore";

/* Utils */
import { useUniqueId } from "@src/hooks";
import { SignupValidationSchema } from "./validation";

type Props = {
  children?: React.ReactNode;
};

type RegisterFormValues = {
  email: string;
  password: string;
  name: string;
  captcha: boolean;
  submitError: null;
};

export const RegisterModal: React.FC<Props> = (props) => {
  const {} = props;

  /* Stores */
  const { isRegisterOpen, setModalOpen } = useRegisterModal();

  /* States */
  const [intialValues, setInitialValues] = useState<RegisterFormValues>({
    captcha: false,
    email: "",
    name: "",
    password: "",
    submitError: null,
  });

  /* Callbacks */
  const setRegisterOpen = useCallback(
    (val: boolean) => {
      setModalOpen({ isRegisterOpen: val });
    },
    [setModalOpen],
  );

  /* Memos */
  const toastId = useUniqueId("register-toast-id-");

  /* APIs */
  const signupMutation = useSignup();

  const onSubmit = useCallback(
    async (
      values: RegisterFormValues,
      helpers: FormikHelpers<RegisterFormValues>,
    ) => {
      const { setFieldError } = helpers;

      const payload = {
        email: values.email,
        password: values.password,
        name: values.name,
        activated: false,
      };

      Toast.loading("Creating User...", { id: toastId });
      try {
        const res = await signupMutation.mutateAsync(payload);
        if (res.isSuccess) {
          Toast.success("User Created Successfully", { id: toastId });
          setRegisterOpen(false);
        } else
          Toast.error("Something went wrong while creating user", {
            id: toastId,
          });
      } catch (e: any) {
        const error =
          e?.data?.message || "Something went wrong while creating user";
        setFieldError("submitError", error);
        Toast.error(error, { id: toastId });
      }
    },
    [setRegisterOpen, signupMutation, toastId],
  );

  return (
    <Modal
      style={{ "--z-index": "0" }}
      wrapperClasses="mt-12 lg:mt-0"
      width="40%"
      className="border-gradient bg-gradient !rounded-3xl p-6"
      isModalOpen={isRegisterOpen}
      setModalOpen={setRegisterOpen}
    >
      <div className="relative">
        <Button
          variant="icon"
          onClick={() => setRegisterOpen(false)}
          className="absolute top-0 right-0"
        >
          <XMarkIcon fontSize={24} width={24} height={24} color="white" />
        </Button>
        <div className="flex flex-col gap-4 p-6">
          <div className="text-center mb-6">
            <Heading size="xl">Please Sign up to contiune</Heading>
          </div>
          <Formik
            validationSchema={SignupValidationSchema}
            onSubmit={onSubmit}
            initialValues={intialValues}
          >
            {({ handleSubmit, errors }) => (
              <div className="flex flex-col gap-4">
                <Input
                  name="name"
                  label=""
                  type="text"
                  placeholder="Instiution/Organization"
                />
                <Input
                  name="email"
                  label=""
                  type="email"
                  placeholder="Username or Email"
                />
                <Input
                  name="password"
                  label=""
                  type="password"
                  placeholder="Password"
                />
                <div className="flex gap-2 flex-col">
                  <Button
                    disabled={signupMutation.isPending}
                    type="submit"
                    className="!w-full"
                    variant="solid"
                    onClick={handleSubmit as any}
                  >
                    Sign Up
                  </Button>
                  <Text color="red">{errors.submitError}</Text>
                </div>
              </div>
            )}
          </Formik>
          <div className="grid grid-cols-[5fr_1fr_5fr] items-center">
            <div className="h-[1px] bg-grey rounded-full" />
            <Text className="text-center">Or</Text>
            <div className="h-[1px] bg-grey rounded-full" />
          </div>
          <ThirdPartyAuth />
          <div className="text-center isolate">
            <Text as="span" className="mt-2">
              Already have an account?
            </Text>
            <Button
              onClick={() =>
                setModalOpen({ isLoginOpen: true, isRegisterOpen: false })
              }
              color="primary"
              className="!inline !pl-2 z-1"
              variant="link"
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
