"use client";

import gsap from "gsap";
import { useEffect, useRef } from "react";

/* Stores */
import { useNavigationDrawer } from "@src/store";

import { useWindowSize } from "@src/hooks";

export const Hamburger: React.FC = () => {
  /* Store */
  const { isDrawerOpen, setDrawerOpen } = useNavigationDrawer();

  /* Hooks */
  const { sizes, bp } = useWindowSize();

  /* Refs */
  const rootRef = useRef<HTMLButtonElement>(null);
  let timeline = useRef<gsap.core.Timeline | null>(null);

  /* Effects */
  useEffect(() => {
    let ctx = gsap.context(() => {
      timeline.current = gsap.timeline({ paused: true });
      HamburgerAnim(timeline.current!);
    }, rootRef);

    return () => ctx.revert();
  }, []);

  useEffect(() => {
    if (sizes.width > bp.md) setDrawerOpen(false);
  }, [bp.md, setDrawerOpen, sizes.width]);

  useEffect(() => {
    if (isDrawerOpen) timeline.current?.play();
    else timeline.current?.reverse();
  }, [isDrawerOpen]);

  /* Handlers */
  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="z-20 lg:hidden">
      <button ref={rootRef} className="text-primary" onClick={handleClick}>
        <Icon />
      </button>
    </div>
  );
};

const Icon: React.FC = () => (
  <svg
    id="prefix__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    height={48}
    width={48}
    viewBox="0 0 46.27 32.26"
    className="fill-current"
  >
    <rect id="rect-one" width={46.27} height={6.23} rx={2.67} />
    <rect id="rect-two" y={13.02} width={46.27} height={6.23} rx={2.67} />
    <rect id="rect-three" y={26.03} width={46.27} height={6.23} rx={2.67} />
  </svg>
);

function HamburgerAnim(timeline: gsap.core.Timeline): void {
  timeline
    .to("#prefix__Layer_1", {
      rotate: 180,
      ease: "power1.inOut",
      duration: 0.45,
    })
    .to("#rect-one", {
      transformOrigin: "center left",
      rotate: 45,
      x: 5,
      stagger: 1,
      delay: -0.25,
    })
    .to("#rect-two", {
      transformOrigin: "center left",
      opacity: 0,
      x: 20,
      delay: -0.5,
    })
    .to("#rect-three", {
      transformOrigin: "center left",
      y: 7,
      x: 5,
      rotate: -45,
      delay: -0.75,
    });
}
