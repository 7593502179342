"use client";

import Link from "next/link";
import React, { useEffect, useMemo, useState } from "react";

/* Components */
import { Logo } from "@src/assets/logo";
import { SuspenseSkeleton, Text } from "@components";
import { NavigationItems } from "./components";

/* Hooks */
import { useUniqueId, useWindowSize } from "@src/hooks";

/* Utils */
import { FormatDate } from "@utils/dates-fns";

/* Data */
import { FOOTER_DATA } from "./data";

/* Types */
import type { HomePageFooter } from "@src/service/getHeader";

type Props = {
  children?: React.ReactNode;
};

const HomeFooter: React.FC<Props> = (props) => {
  const {} = props;

  /* State */
  const data = useMemo<HomePageFooter>(() => FOOTER_DATA, []);

  /* Hooks */
  const { bp, sizes } = useWindowSize();

  /* Memos */
  const currentYear = useMemo(() => FormatDate(new Date(), "yyyy"), []);

  /* Keys */
  const navigationKey = useUniqueId("home-footer-navigation-");

  return (
    <div className="container mt-12 flex flex-col gap-12">
      <div className="flex lg:flex-row flex-col justify-between items-center">
        <div className="grid place-items-center">
          <Logo fontSize="6rem" />
        </div>
      </div>
      <div className="grid grid-rows-[2fr_1fr_1fr_2fr] text-center lg:text-left lg:grid-rows-1 lg:grid-cols-4">
        <SuspenseSkeleton count={4} height="100%" width="25%">
          {data && (
            <>
              <div className="flex flex-col gap-6">
                <NavigationItems title="Solutions" items={data.solutions} />
                <NavigationItems
                  title="Certifications"
                  items={data.certifications}
                />
              </div>
              <NavigationItems
                title="Training Library"
                items={data.trainingLibrary}
              />
              <NavigationItems title="Resources" items={data.resources} />
              <div className="flex flex-col gap-6">
                <NavigationItems title="Past Events" items={data.events} />
                <NavigationItems
                  title="Course Index"
                  items={data.courseIndex}
                />
              </div>
            </>
          )}
        </SuspenseSkeleton>
      </div>
      <Text>{`Copyright © ${currentYear}. Powered by Moss Tech.`}</Text>
    </div>
  );
};

export default HomeFooter;
