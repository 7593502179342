import { Suspense } from "react";

import Skeleton from "react-loading-skeleton";

export function withSuspense<T extends Record<string, unknown>>(
  Component: React.ComponentType<T>,
  LoaderComponent?: React.ReactNode | React.FC
) {
  const ComponentWithSuspense: React.FC<T> = (props) => (
    <Suspense
      fallback={
        (LoaderComponent as React.ReactNode) || (
          <Skeleton enableAnimation width="100%" />
        )
      }
    >
      <Component {...props} />
    </Suspense>
  );

  return ComponentWithSuspense;
}
