import * as Dialog from "@radix-ui/react-dialog";

/* Stylec */
import { styled } from "@styles/stitches";

export const ModalContent = styled(Dialog.Content, {
  position: "fixed",
  top: "50%",
  left: "50%",
  width: "100%",
  marginTop: "3rem",
  transform: "translateX(-50%)",
  backgroundColor: "$base-100",
  zIndex: 10,
  boxShadow: "0 0 0 1px $base-100",

  "@md": {
    width: "75%",
  },

  "@lg": {
    width: "var(--width, 50%)",
    marginTop: 0,
  },
});

export const ModalOverlay = styled(Dialog.Overlay, {
  backgroundColor: "rgba(0, 0, 0, 0.45)",
  zIndex: 10,
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  backdropFilter: "blur(10px)",
});
