import { create } from "zustand";

type NavigationState = {
  isDrawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  routeIdx: number;
  setRoute: (routeIdx: number) => void;
};

const navigationStore = create<NavigationState>((set) => ({
  isDrawerOpen: false,
  setDrawerOpen: (val) => set((state) => ({ ...state, isDrawerOpen: val })),
  routeIdx: 1,
  setRoute: (route) => set((state) => ({ ...state, route })),
}));

export const useNavigationDrawer = () =>
  navigationStore(({ isDrawerOpen, setDrawerOpen }) => ({
    isDrawerOpen,
    setDrawerOpen,
  }));

export const useNavigationStore = () =>
  navigationStore(({ routeIdx, setRoute }) => ({ routeIdx, setRoute }));
