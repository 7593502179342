import React from "react";

/* Components */
import { Button } from "@components";
import {
  GoogleIcon,
  FacebookIcon,
  LinkedInIcon,
  GithubIcon,
} from "@components/icons";
import { useLocalStorage } from "@src/hooks";

type ThirdPartyAuthProps = {
  isLogin?: boolean;
};

export const ThirdPartyAuth: React.FC<ThirdPartyAuthProps> = (props) => {
  const { isLogin } = props;

  const { set, KEYS } = useLocalStorage();

  /* Handlers */
  const signIn = (vendor: "google" | "linkedin" | "github" | "facebook") => {
    set(KEYS.REDIRECT_URI, window.location.pathname);

    window.location.href = `${process.env.NEXT_PUBLIC_API_URL}/auth/${vendor}`;
  };

  return (
    <>
      <div className="flex flex-col md:flex-row gap-4">
        <Button
          onClick={() => signIn("google")}
          rightIcon={() => <GoogleIcon fontSize={20} />}
          variant="ghost"
          className="!w-full"
          typographyProps={{ color: "primary" }}
        >
          Sign {isLogin ? "In" : "Up"} with
        </Button>
        <Button
          onClick={() => signIn("facebook")}
          rightIcon={() => <FacebookIcon fontSize={20} />}
          variant="ghost"
          className="!w-full"
          typographyProps={{ color: "primary" }}
        >
          Sign {isLogin ? "In" : "Up"} with
        </Button>
      </div>
      <div className="flex flex-col md:flex-row gap-4">
        <Button
          onClick={() => signIn("linkedin")}
          rightIcon={() => <LinkedInIcon fontSize={20} />}
          variant="ghost"
          className="!w-full"
          typographyProps={{ color: "primary" }}
        >
          Sign {isLogin ? "In" : "Up"} with
        </Button>
        <Button
          onClick={() => signIn("github")}
          rightIcon={() => <GithubIcon fontSize={20} />}
          variant="ghost"
          className="!w-full"
          typographyProps={{ color: "primary" }}
        >
          Sign {isLogin ? "In" : "Up"} with
        </Button>
      </div>
    </>
  );
};
