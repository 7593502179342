"use client";

import React from "react";

/* Components */
import { Drawer, Button } from "@components";
import { MobileNavItems } from "./components/navigationItems";

/* Stores */
import { useNavigationDrawer, useModalOpen } from "@stores";

type Props = {
  children?: React.ReactNode;
};

export const MobileDrawer: React.FC<Props> = (props) => {
  const {} = props;

  const { setModalOpen } = useModalOpen();
  const { isDrawerOpen, setDrawerOpen } = useNavigationDrawer();

  return (
    <Drawer
      wrapperClasses="mt-20"
      className="bg-gradient pt-10 rounded-lg text-center"
      isDrawerOpen={isDrawerOpen}
      setDrawerOpen={setDrawerOpen}
    >
      <MobileNavItems isMobile />

      <div className="flex flex-col gap-12 mx-10 mt-12 items-center">
        <Button
          color="primary"
          className="!w-full"
          onClick={() => {
            setDrawerOpen(false);
            setModalOpen({ isLoginOpen: true });
          }}
          typographyProps={{ color: "primary" }}
          variant="solid"
        >
          Login
        </Button>
        <Button
          color="primary"
          className="!w-full"
          onClick={() => {
            setDrawerOpen(false);
            setModalOpen({ isRegisterOpen: true });
          }}
          typographyProps={{ color: "primary" }}
          variant="solid"
        >
          Register
        </Button>
      </div>
    </Drawer>
  );
};
