import { AxiosWrapper } from "../axios";
import { ENDPOINTS } from "../endpoints";

/* Types */
import type { HeaderTypes, ResponseType } from "./types";

export const getHeader = async <T>(
  typeHeader: HeaderTypes
): Promise<ResponseType<T>> => {
  return await AxiosWrapper({
    method: "GET",
    url: ENDPOINTS.getHeader,
    params: { typeHeader },
  });
};
