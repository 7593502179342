import gsap from "gsap";
import CustomEase from "gsap/CustomEase";
import React, { useEffect, useRef } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Transition } from "react-transition-group";

gsap.registerPlugin(CustomEase);

/* Stitches */
import { ModalContent, ModalOverlay } from "./styled";

type Props = {
  TriggerComponent?: React.FC<unknown>;
  children?: React.ReactNode;
  setModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
  className?: string;
  wrapperClasses?: string;
  width?: string | number;
  style?: React.CSSProperties | Record<string, unknown>;
  onMount?: () => void;
};

const Animation = (ref: HTMLDivElement, isModalOpen: boolean) => {
  const tweenOpts: gsap.TweenVars = {
    ease: CustomEase.create("custom", "M0,0 C0.82,0.085 0.395,0.895 1,1 "),
  };

  if (isModalOpen)
    return gsap.fromTo(
      ref,
      { y: "-60%", opacity: 0, ...tweenOpts },
      { y: "-50%", opacity: 1, ...tweenOpts },
    );

  return gsap.to(ref, { y: "-60%", opacity: 0, ...tweenOpts });
};

export const Modal: React.FC<Props> = (props) => {
  const {
    TriggerComponent,
    children,
    isModalOpen,
    setModalOpen,
    className,
    wrapperClasses,
    onMount,
    ...rest
  } = DefaultProps(props);

  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    toggleScroll(isModalOpen);
  }, [isModalOpen]);

  return (
    <Dialog.Root open={isModalOpen} onOpenChange={setModalOpen}>
      {TriggerComponent && (
        <Dialog.Trigger>
          <TriggerComponent />
        </Dialog.Trigger>
      )}
      <ModalOverlay />
      <Transition
        in={isModalOpen}
        unmountOnExit
        mountOnEnter
        nodeRef={contentRef}
        timeout={500}
        addEndListener={(done) => {
          Animation(contentRef.current!, isModalOpen).then(() => done());
          onMount?.();
        }}
      >
        <ModalContent
          className={wrapperClasses}
          {...rest}
          forceMount
          ref={contentRef}
        >
          <div
            className={`relative h-full border-gradient p-6 bg-gradient ${className}`}
          >
            {children}
          </div>
        </ModalContent>
      </Transition>
    </Dialog.Root>
  );
};

const DefaultProps = (props: Props) => {
  const style = {
    ...{ "--z-index": "0", ...props.style },
    "--width": props.width || "40%",
  };

  const finalProps: Props = {
    ...props,
    className: props.className || "",
    wrapperClasses: props.wrapperClasses || "",
    style,
  };

  delete finalProps.width;
  return finalProps;
};

const toggleScroll = (isModalOpen: boolean) => {
  if (!document) return;
  const container = document.querySelector("#main-container");
  const body = document.querySelector("body");
  if (isModalOpen) {
    container?.classList.add("overflow-hidden");
    body?.classList.add("overflow-hidden");
  } else {
    body?.classList.remove("overflow-hidden");
    container?.classList.remove("overflow-hidden");
  }
};
