"use client";

import Link from "next/link";
import { Formik, type FormikHelpers } from "formik";
import React, { useCallback, useState } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

/* Components */
import { Toast } from "@components/Toast";
import { Modal } from "@components/Modal";
import { Input } from "@components/Input";
import { ThirdPartyAuth } from "../shared";
import { Button, Heading, Text } from "@components";

/* Services */
import { useLogin } from "@src/service/auth";

/* Stores & Contexts */
import { useLoginModal } from "@src/store/modalStore";
import { AUTH_ACTIONS, useAuth } from "@src/contexts/auth";

/* Utils */
import { useUniqueId } from "@src/hooks";
import { LoginValidationSchema } from "./validation";

type Props = {
  children?: React.ReactNode;
};

type LoginFormPayload = {
  email: string;
  password: string;
  submitError: null;
};

export const LoginModal: React.FC<Props> = (props) => {
  const {} = props;

  /* Stores */
  const { isLoginOpen, setModalOpen } = useLoginModal();

  /* APIs */
  const loginMut = useLogin();

  /* Hooks */
  const { dispatch } = useAuth();

  /* States */
  const [intialValues] = useState<LoginFormPayload>({
    email: "",
    password: "",
    submitError: null,
  });

  /* Callbacks */
  const setLoginOpen = useCallback(
    (val: boolean) => {
      setModalOpen({ isLoginOpen: val });
    },
    [setModalOpen],
  );

  const toastId = useUniqueId("login-toast-id-");

  /* Handlers */
  const onSubmit = useCallback(
    async (
      values: LoginFormPayload,
      helpers: FormikHelpers<LoginFormPayload>,
    ) => {
      const { setFieldError } = helpers;

      const { submitError, ...payload } = values;
      Toast.loading("Logging in...", { id: toastId });
      try {
        const res = await loginMut.mutateAsync(payload);
        if (!res.data) throw new Error("Data not found");

        dispatch({ type: AUTH_ACTIONS.LOGIN });

        const url = new URL(window.location.href);
        const redirectURL = url.searchParams.get("redirect");

        let finalUrl = `${url.origin}${
          redirectURL ? redirectURL : url.pathname
        }`;

        // use window router to navigate to the final url
        window.location.replace(finalUrl);

        Toast.success("Logged in successfully", { id: toastId });
        setLoginOpen(false);
      } catch (e) {
        Toast.error("Invalid credentials", { id: toastId });
        setFieldError("submitError", "Invalid credentials");
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginMut, setLoginOpen, toastId],
  );

  return (
    <Modal
      style={{ "--z-index": "0" }}
      width="40%"
      className="!rounded-3xl"
      isModalOpen={isLoginOpen}
      setModalOpen={setLoginOpen}
    >
      <div className="relative">
        <Button
          variant="icon"
          onClick={() => setLoginOpen(false)}
          className="absolute top-0 right-0"
        >
          <XMarkIcon fontSize={24} width={24} height={24} color="white" />
        </Button>
        <div className="flex flex-col gap-4 p-6">
          <div className="text-center mb-6">
            <Heading size="xl">Please Login to contiune</Heading>
          </div>
          <Formik
            validationSchema={LoginValidationSchema}
            onSubmit={onSubmit}
            initialValues={intialValues}
          >
            {({ handleSubmit, errors }) => (
              <div className="flex flex-col gap-4">
                <Input
                  name="email"
                  label=""
                  type="email"
                  placeholder="Username or Email"
                />
                <Input
                  name="password"
                  label=""
                  type="password"
                  placeholder="Password"
                />
                <div className="flex justify-between">
                  <div className="flex gap-2">
                    <Input
                      wrapperClassName="!w-fit"
                      name="remember-me"
                      type="checkbox"
                    />
                    <Text className="!w-32">Remember me</Text>
                  </div>
                  <Link href="#">
                    <Text as="a">Forgot Password?</Text>
                  </Link>
                </div>
                <div className="flex flex-col gap-2">
                  <Button
                    type="submit"
                    name="submitError"
                    className="!w-full"
                    variant="solid"
                    onClick={handleSubmit as any}
                  >
                    Sign In
                  </Button>
                  <Text color="red">{errors.submitError}</Text>
                </div>
              </div>
            )}
          </Formik>
          <div className="grid grid-cols-[5fr_1fr_5fr] items-center">
            <div className="h-[1px] bg-grey rounded-full" />
            <Text className="text-center">Or</Text>
            <div className="h-[1px] bg-grey rounded-full" />
          </div>
          <ThirdPartyAuth isLogin />
          <div className="text-center isolate">
            <Text as="span" className="mt-2">
              {"Don't"} have an account?
            </Text>
            <Button
              onClick={() =>
                setModalOpen({ isLoginOpen: false, isRegisterOpen: true })
              }
              color="primary"
              className="!inline !pl-2 z-1"
              variant="link"
            >
              Sign Up
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
