import { create } from "zustand";

type ModalState = {
  isRegisterOpen: boolean;
  isLoginOpen: boolean;
  setModalOpen: (value: States) => void;
};

type States = Omit<Partial<ModalState>, "setModalOpen">;

export const modalStore = create<ModalState>((set) => ({
  isRegisterOpen: false,
  isLoginOpen: false,
  setModalOpen: (values: States) => set((state) => ({ ...state, ...values })),
}));

export const useRegisterModal = () =>
  modalStore(({ isRegisterOpen, setModalOpen }) => ({
    isRegisterOpen,
    setModalOpen,
  }));

export const useLoginModal = () =>
  modalStore(({ isLoginOpen, setModalOpen }) => ({
    isLoginOpen,
    setModalOpen,
  }));

export const useModalOpen = () =>
  modalStore(({ setModalOpen }) => ({ setModalOpen }));
