import React, { createContext, useContext, useMemo, useReducer } from "react";

/* Hooks */
import { useCookie } from "@src/hooks";

/* Consts */
import { LOCAL_STORAGE_KEYS } from "@src/consts";

/* Utils */
import {
  getLocalStoragevalue,
  setLocalStoragevalue,
} from "@utils/localStorage";

/* Types */
import type { PropsWithChildren } from "react";

export const AuthContext = createContext<AuthContextType>({
  state: { user: null, isLoggedIn: false },
  dispatch: () => {},
});

export enum AUTH_ACTIONS {
  _UPDATE_USER = "update-user",
  LOGOUT = "logout",
  LOGIN = "login",
}

type AuthUser = {
  id: number;
  name: string;
  email: string;
  activated: boolean;
};

export type AuthState = {
  user: AuthUser | null;
  isLoggedIn: boolean;
};

type AuthContextType = {
  state: AuthState;
  dispatch: React.Dispatch<AuthAction>;
};

// An interface for our actions
type AuthAction =
  | {
      // Only use in one place to avoid confusion. Currently being used in UserProfile Component.
      type: AUTH_ACTIONS._UPDATE_USER;
      user: AuthState["user"];
      isLoggedIn?: never;
    }
  | {
      type: AUTH_ACTIONS.LOGOUT;
      user?: never;
      isLoggedIn?: never;
    }
  | {
      type: AUTH_ACTIONS.LOGIN;
      user?: never;
      isLoggedIn?: never;
    };

function FormDeleteReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case AUTH_ACTIONS.LOGIN:
      return {
        ...state,
        isLoggedIn: true,
      };

    case AUTH_ACTIONS._UPDATE_USER:
      setLocalStoragevalue(
        LOCAL_STORAGE_KEYS.USER_DETAILS,
        action.user || null,
      );
      return {
        ...state,
        isLoggedIn: Boolean(action.user),
        user: action.user,
      };

    case AUTH_ACTIONS.LOGOUT:
      setLocalStoragevalue(LOCAL_STORAGE_KEYS.USER_DETAILS, null);
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    default:
      throw new Error(
        `Please select a valid action for role reducer. Value: ${
          (action as any).type
        }`,
      );
  }
}

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { getCookie, COOKIE_KEYS } = useCookie();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const accessToken = useMemo(() => getCookie(COOKIE_KEYS.AUTH_TOKEN), []);
  const user = useMemo(
    () => getLocalStoragevalue(LOCAL_STORAGE_KEYS.USER_DETAILS),
    [],
  );

  const [state, dispatch] = useReducer(FormDeleteReducer, {
    user: accessToken ? user || null : null,
    isLoggedIn: !!accessToken,
  });

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext<AuthContextType>(AuthContext);
