import { useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";

/* Keys */
import { HEADER_KEYS } from "../keys";

/* Handler */
import { getHeader } from "./handler";

/* Types */
import type { HeaderTypes, ResponseType } from "./types";

export const useGetHeader = <T>(defaultValue?: HeaderTypes) => {
  const [header, setHeader] = useState<HeaderTypes | null>(
    defaultValue || null,
  );

  const query = useSuspenseQuery<ResponseType<T>, ResponseType>({
    queryKey: [HEADER_KEYS.GET_HEADER, header],
    queryFn: () => getHeader<T>(header!),
  });

  return [query, setHeader] as const;
};
