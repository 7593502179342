"use client";

import React from "react";
import * as RadixSwitch from "@radix-ui/react-switch";

/* Components */
import { switchStyles, thumbStyles } from "./styled.css";
import { MoonIcon, SunIcon } from "@components";
import { useTheme, useDark } from "@stores";

type Props = {
  children?: React.ReactNode;
};

export const ThemeToggle: React.FC<Props> = (props) => {
  const {} = props;

  const { toggleTheme } = useTheme();
  const { isDark } = useDark();

  return (
    <RadixSwitch.Root
      onClick={toggleTheme}
      checked={isDark}
      aria-label="Light and Dark mode Toggle"
      className={`${switchStyles} bg-gray-300`}
    >
      <RadixSwitch.SwitchThumb className={thumbStyles} />
      <div
        role="button"
        tabIndex={0}
        aria-label="Light mode toggle button"
        className="absolute z-1 translate-y-1.5 translate-x-2.5"
      >
        <SunIcon
          fill="currentColor"
          className={isDark ? "text-primary" : "text-white"}
          fontSize={24}
        />
      </div>
      <div
        role="button"
        tabIndex={0}
        aria-label="Dark mode toggle button"
        className="absolute z-1 translate-y-1.5 translate-x-12"
      >
        <MoonIcon
          fill="currentColor"
          fontSize={24}
          className={isDark ? "text-white" : "text-primary"}
        />
      </div>
    </RadixSwitch.Root>
  );
};
