import React from "react";

/* Components */
import { UserIcon } from "@components/icons";
import { Dropdown } from "@components/Dropdown";

/* Types */
import type { DropdownMenuType } from "@components/Dropdown";

type Props =
  | {
      hasDropdown?: true;
      DropdownContent: DropdownMenuType;
    }
  | {
      hasDropdown?: false;
      DropdownContent?: never;
    };

export const Avatar: React.FC<Props> = (props) => {
  const { hasDropdown, DropdownContent } = props;

  if (hasDropdown)
    return (
      <Dropdown
        TriggerComponent={() => (
          <div className="p-1.5 bg-secondary rounded-full">
            <UserIcon />
          </div>
        )}
      >
        {DropdownContent}
      </Dropdown>
    );

  return (
    <button className="p-1.5 bg-secondary rounded-full">
      <UserIcon />
    </button>
  );
};
