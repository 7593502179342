"use client";
import React, { useEffect } from "react";
import { useRouter } from "next/navigation";

/* Components */
import { Button, Text } from "@components";
import { Avatar } from "@components/Avatar";

/* Stores */
import { useModalOpen } from "@stores";

/* Hooks */
import { useCookie, useLocalStorage } from "@src/hooks";

/* Context */
import { AUTH_ACTIONS, useAuth } from "@src/contexts";

/* Types */
import type { MenuType } from "@components";
import type { UserType } from "@src/service/auth";

type Props = {
  children?: React.ReactNode;
  user: UserType | null;
};

export const NavigationProfile: React.FC<Props> = (props) => {
  const { user } = props;

  const { dispatch } = useAuth();

  useEffect(() => {
    if (user) {
      dispatch({
        type: AUTH_ACTIONS._UPDATE_USER,
        user: {
          activated: user.activated,
          email: user.email,
          id: user.id,
          name: user.name,
        },
      });
    }
  }, [dispatch, user]);

  /* Stores */
  const { setModalOpen } = useModalOpen();

  if (user)
    return (
      <div
        suppressHydrationWarning
        className="flex justify-end items-center gap-8"
      >
        <Text>{user.name}</Text>
        <Avatar hasDropdown DropdownContent={DropdownContent} />
      </div>
    );

  return (
    <div suppressHydrationWarning className="flex gap-4">
      <Button
        onClick={() => setModalOpen({ isRegisterOpen: true })}
        typographyProps={{ color: "primary" }}
        variant="text"
      >
        Register
      </Button>
      <Button
        onClick={() => setModalOpen({ isLoginOpen: true })}
        size="lg"
        className="!px-8"
        variant="outline"
        color="gradient"
      >
        Sign In
      </Button>
    </div>
  );
};

const DropdownContent = (Dropdown: MenuType) => {
  const { deleteCookie, COOKIE_KEYS } = useCookie();
  const { dispatch } = useAuth();
  const { KEYS, remove } = useLocalStorage();

  const router = useRouter();

  /* Handlers */
  const logout = () => {
    // Remove local storage
    remove(KEYS.USER_DETAILS);

    // Update context
    dispatch({ type: AUTH_ACTIONS.LOGOUT });

    // Delete cookies
    deleteCookie(COOKIE_KEYS.AUTH_TOKEN);
    deleteCookie(COOKIE_KEYS.REFRESH_TOKEN);

    router.refresh();
  };

  return (
    <div className="flex flex-col gap-4">
      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
    </div>
  );
};
